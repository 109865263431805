/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type Props = {
    info: any,
}

export const MemberStatistics: FC<Props> = ({ info }) => {
    //console.log("info", info)
    const memberStatistics = info.memberStatistics

    const color = '#E91146' //Issue 3.- 1.4.3 Contrast (Minimum) [Level AA]
    const img = ''//toAbsoluteUrl('/media/patterns/vector-1.png')


    return (
        <div className={`card card-flush mb-5 mb-xl-10`}
            style={{
                backgroundColor: color,
                backgroundImage: `url('${img}')`,
            }}>
            <div className='card-header pt-0'>
                <div className='card-title d-flex flex-column m-0'>
                    <div className='d-flex align-items-center d-none'>
                        <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span>

                        <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>69,700</span>

                        <span className='badge badge-light-success fs-base'>
                            <KTIcon iconName='arrow-up' className='fs-5 text-success ms-n1' /> 2.2%
                        </span>
                    </div>
                    <div className='card-title text-white fw-bold dashboard-h3'>
                        <a href="#" className='card-title text-white fw-bold' data-main-content>
                            Member Statistics
                        </a>
                    </div>
                </div>
            </div>

            <div className='card-body pt-1 pb-1 d-flex flex-wrap align-items-center'>
                <div className='d-flex flex-column content-justify-center flex-row-fluid'>
                    <div className='d-flex fw-semibold align-items-center my-3'>
                        <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
                        <div className='text-white flex-grow-1 me-4'># Members registered</div>
                        <div className='fw-bolder text-white text-xxl-end'>{memberStatistics.membersRegistered}</div>
                    </div>
                    <div className='d-flex fw-semibold align-items-center my-3'>
                        <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
                        <div className='text-white flex-grow-1 me-4'># Members accessing platform</div>
                        <div className='fw-bolder text-white text-xxl-end'>{memberStatistics.membersAccessingPlatform}</div>
                    </div>
                    <div className='d-flex fw-semibold align-items-center my-3'>
                        <div className='bullet w-8px h-3px rounded-2 bg-warning me-3'></div>
                        <div className='text-white flex-grow-1 me-4'># Members accessing resources</div>
                        <div className='fw-bolder text-white text-xxl-end'>{memberStatistics.membersAccessingResources}</div>
                    </div>
                </div>

            </div>
        </div >
    )
}

const initChart = function (
    chartSize: number = 70,
    chartLine: number = 11,
    chartRotate: number = 145
) {
    const el = document.getElementById('kt_card_widget_17_chart')
    if (!el) {
        return
    }
    el.innerHTML = ''

    var options = {
        size: chartSize,
        lineWidth: chartLine,
        rotate: chartRotate,
        //percent:  el.getAttribute('data-kt-percent') ,
    }

    const canvas = document.createElement('canvas')
    const span = document.createElement('span')

    // @ts-ignore
    if (typeof G_vmlCanvasManager !== 'undefined') {
        // @ts-ignore
        G_vmlCanvasManager.initElement(canvas)
    }

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size

    el.appendChild(span)
    el.appendChild(canvas)

    // @ts-ignore
    ctx.translate(options.size / 2, options.size / 2) // change center
    // @ts-ignore
    ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

    //imd = ctx.getImageData(0, 0, 240, 240);
    const radius = (options.size - options.lineWidth) / 2

    const drawCircle = function (color: string, lineWidth: number, percent: number) {
        percent = Math.min(Math.max(0, percent || 1), 1)
        if (!ctx) {
            return
        }

        ctx.beginPath()
        ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
        ctx.strokeStyle = color
        ctx.lineCap = 'round' // butt, round or square
        ctx.lineWidth = lineWidth
        ctx.stroke()
    }

    // Init 2
    drawCircle('#E4E6EF', options.lineWidth, 100 / 100)
    drawCircle(getCSSVariableValue('--bs-primary'), options.lineWidth, 100 / 150)
    drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, 100 / 250)
}


