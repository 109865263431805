import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../_metronic/helpers'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

//export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
//export const LOGIN_URL = `${API_URL}/login`
export const GET_USER_BY_ACCESSTOKEN_URL = `/verify_token`
export const LOGIN_URL = `/login`


export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const GET_USER_LOGIN_TOKEN = `${API_URL}/user/getUserLoginToken`

//login demo
export function loginDemo(email: string, password: string) {
  //let url = DEMO_URL + 'api/account/login';
  //url = "https://localhost:44311/weatherforecast";
  let url = '/api/account/login';
  //console.log("URL=", url);
  
  //return true;
  return axios.post<boolean>(url, {
    email,
    password,
  })
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function getUserByEmail(email: string, password: string) {
    return axios.post<UserModel>("/getUserLoginByEmail", { email, password })
}

export const getUserLoginToken = (token: string): Promise<UserModel | undefined> => {
  return axios
    .get(`/api/user/getUserLoginToken/${token}`)
    .then((response: AxiosResponse<Response<UserModel>>) => {
      return response.data
    })
    .then((response: Response<UserModel>) => {
      return response.data
    })
}

export const confirmUserLogin = (row: any): Promise<any> => {
  return axios
    .post(`/api/user/confirmUserLogin`, row)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}



export function getUserLoginToken2(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
