import { useQuery } from 'react-query'
import { SidebarMenuCategories } from './SidebarMenuCategories'
import { isNotEmpty, ISurveyParm, QUERIES } from '../../../../../_metronic/helpers'
import { LoadingIndicator } from '../../../../../_metronic/helpers/components/LoadingIndicator'
import { getSurveyCategoryInfo } from '../../../../../app/modules/surveys/core/_requests'


export const SidebarMenuCategoriesWrapper: React.FC<ISurveyParm> = ({ categoryRowId, surveyRowId, surveyAnswerCategoryRowId } ) => {
	let itemIdForUpdate = 1;
	//console.log('categoryRowId', categoryRowId)
	//console.log('surveyRowId', surveyRowId)
	//const { itemIdForUpdate, setItemIdForUpdate } = useListView()
	const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
	const {
		isLoading,
		data: data,
		error,
	} = useQuery(
		`${QUERIES.GET_SURVEY_CATEGORY_INFO}--${surveyRowId}`,
		() => {
			return getSurveyCategoryInfo(surveyRowId)
		},
		{
			cacheTime: 0,
			enabled: enabledQuery,
			onError: (err) => {
				//setItemIdForUpdate(undefined)
				console.error(err)
			},
		}
	)

	//console.log("data2", data);
	//console.log("isLoading2", isLoading);
	

	if (isLoading) {
		return LoadingIndicator();
	} else {
		return <SidebarMenuCategories lookups={data.lookups} categoryRowId={categoryRowId} surveyRowId={surveyRowId} surveyAnswerCategoryRowId={surveyAnswerCategoryRowId} categoryInfo={data.items} isPrimaryServiceProvider={data.isPrimaryServiceProvider} />
	}




}
