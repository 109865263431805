import linq from 'linq'
import { getAuthorizationHeader } from '../../app/modules/authentication/components/AuthHelpers'
import { getFileDownloadName } from './index'
import { CATEGORY, SERVICE_PROVIDER_PROGRAMS, PRIMARY_SERVICE_PROVIDER, ORGANIZATION, ORGANIZATIONAL_STAFF, THIRD_PARTY_VENDOR, BOTH, CATEGORY_ORGANIZATION_ROWID, CATEGORY_PRIMARYSERVICEPROVIDER_ROWID } from './index'

export const getCategoryRowId = (lookups: any) => (linq.from(lookups.surveyTypes).single((val: any) => val.name === CATEGORY) as any).rowId
export const getServiceProviderProgramsRowId = (lookups: any) => (linq.from(lookups.surveyTypes).single((val: any) => val.name === SERVICE_PROVIDER_PROGRAMS) as any).rowId
export const getParentCategoryRowId = (lookups: any, categoryRowId: any) => (linq.from(lookups.categories).singleOrDefault((val: any) => val.rowId == categoryRowId) as any)?.parentCategoryRowId

export const getCategoryOrganization = (lookups: any) => (linq.from(lookups.categories).single((val: any) => val.name == ORGANIZATION) as any).rowId
export const getCategoryProvider = (lookups: any) => (linq.from(lookups.categories).single((val: any) => val.name == PRIMARY_SERVICE_PROVIDER) as any).rowId

export const getCategoryNameByRowId = (lookups: any, categoryRowId) => (linq.from(lookups.categories).singleOrDefault((val: any) => val.rowId == categoryRowId) as any)?.name

export const getOrganizationalStaff = (lookups: any) => (linq.from(lookups.deliveryModes).single((val: any) => val.name == ORGANIZATIONAL_STAFF) as any).rowId
export const getThirdPartyVendor = (lookups: any) => (linq.from(lookups.deliveryModes).single((val: any) => val.name == THIRD_PARTY_VENDOR) as any).rowId
export const getBoth = (lookups: any) => (linq.from(lookups.deliveryModes).single((val: any) => val.name == BOTH) as any).rowId

export const isSpecialCategory = (categoryRowId) => categoryRowId == CATEGORY_ORGANIZATION_ROWID || categoryRowId == CATEGORY_PRIMARYSERVICEPROVIDER_ROWID

export const openSurveyPdf = async (surveyRowId) => {
	fetch(`/api/survey/buildPdf/${surveyRowId}`, {
		method: 'GET',
		headers: {
			'Authorization': getAuthorizationHeader()
		},
	})
		.then(async (response) => {
			const blob = await response.blob()
			const filename = getFileDownloadName(response)
			//console.log('filename', filename)
			//console.log('blob', blob)

			const file = new File([blob], filename, { type: 'application/pdf' })
			//console.log('file', file)
			var _url = window.URL.createObjectURL(file);
			//console.log('_url22', _url)

			const link = document.createElement('a');
			link.href = _url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();

			//window.open(_url, "_blank").focus(); // window.open + focus
		}).catch((err) => {
			console.log(err);
		});
};