import { getAuthorizationHeader } from '../../app/modules/authentication/components/AuthHelpers'
import { getFileDownloadName } from './index'

export const openAnnouncementPdf = async (announcementRowId) => {
	fetch(`getPdf/${announcementRowId}`, {
		method: 'GET',
		headers: {
			'Authorization': getAuthorizationHeader()
		},
	})
		.then(async (response) => {
			const blob = await response.blob()
			const filename = getFileDownloadName(response)
			//console.log('blob', blob)

			const file = new File([blob], filename, { type: 'application/pdf' })
			//console.log('file', file)
			var _url = window.URL.createObjectURL(file);
			//console.log('_url22', _url)

			const link = document.createElement('a');
			link.href = _url;
			link.setAttribute('download', filename);
			document.body.appendChild(link);
			link.click();

			//window.open(_url, "_blank").focus(); // window.open + focus
		}).catch((err) => {
			console.log(err);
		});
};