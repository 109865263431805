/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react';
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { ConfirmAccountWrapper } from '../modules/auth/components/ConfirmAccountWrapper'
import { isShowDashboard, isRequireOrgInfoMode } from '../../_metronic/helpers'
import { App } from '../App'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

type Props = {
    msalInstance: any
}

export const AppRoutes: FC<Props> = ({ msalInstance }) => {
    //const { currentUser } = useAuth()

    const { instance } = useMsal()
    const activeAccount = instance.getActiveAccount()
    console.log("activeAccount", activeAccount)
    console.log("main.isShowDashboard", isShowDashboard())

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App msalInstance={msalInstance} />}>
                    <Route path='error/*' element={<ErrorsPage />} />
                    <Route path='logout' element={<Logout />} />

                    <>
                        <Route path='/*' element={<PrivateRoutes />} />


                        {isRequireOrgInfoMode() ?
                            <Route index element={<Navigate to='/organizations/list' />} /> :
                            isShowDashboard() ?
                                <Route index element={<Navigate to='/dashboard' />} />
                                :
                                <Route index element={<Navigate to='/survey/list' />} />
                        }

                    </>

                    <>
                        <Route path='auth2/*' element={<AuthPage />} />
                    </>


                    {/*{currentUser ? (*/}
                    {/*	<>*/}
                    {/*		<Route path='/*' element={<PrivateRoutes />} />*/}
                    {/*		<Route index element={<Navigate to='/dashboard' />} />*/}
                    {/*	</>*/}
                    {/*) : (*/}
                    {/*	<>*/}
                    {/*		<Route path='auth/*' element={<AuthPage />} />*/}
                    {/*		<Route path='*' element={<Navigate to='/auth' />} />*/}
                    {/*	</>*/}
                    {/*)}*/}

                </Route>
            </Routes>
        </BrowserRouter>
    )
}


