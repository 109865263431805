import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTIcon, WithChildren } from '../../../../helpers'
import { useLayout } from '../../../core'

type Props = {
	to: string
	target?: string
	alink?: boolean
	title: string
	icon?: string
	fontIcon?: string
	hasBullet?: boolean
	onClick?: Function
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
	children,
	to,
	target,
	alink = false,
	title,
	icon,
	fontIcon,
	hasBullet = false,
	onClick = null,
}) => {
	const { pathname, search } = useLocation()
	const isActive = checkIsActive(pathname + search, to)
	const { config } = useLayout()
	const { app } = config

	const inline = (
		<>
			{hasBullet && (
				<span className='menu-bullet'>
					<span className='bullet bullet-dot'></span>
				</span>
			)}
			{icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
				<span className='menu-icon'>
					{' '}
					<KTIcon iconName={icon} className='fs-2' />
				</span>
			)}
			{fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
				<i className={clsx('bi fs-3', fontIcon)}></i>
			)}
			<span className='menu-title'>{title}</span>
		</>
	)

	return (
		<div className='menu-item'>
			{alink &&
				<>
					<Link style={{ outline: '1px solid #666 !important' }} className={clsx('menu-link without-sub', { active: isActive })} to={to} onClick={() => onClick ? onClick() : null}>
						{inline}
					</Link>
					{children}
				</>
			}
			{!alink &&
				<>
					<a className={clsx('menu-link without-sub', { active: isActive })} href={to} target={target}  >
						{inline}
					</a>
					{children}
				</>
			}
		</div>
	)
}

export { SidebarMenuItem }
