import { v4 as uuidv4 } from 'uuid';

export const jsonObjectRemoveNulls = (obj: any) => {

	//const a = []
	//console.log("ZZ", typeof (a)) 
	//console.log("ZZ", Object.keys("123"))
	//return

	const keys = Object.keys(obj)
	//console.log("obj", obj)
	//console.log("keys", keys)
	keys.forEach((key) => {
		if (obj[key] == null) {
			obj[key] = ""
		} else if (Array.isArray(obj[key])) {
			obj[key].forEach((row) => {
				jsonObjectRemoveNulls(row)
			})
		} else if (typeof (obj[key]) === "object") {
			jsonObjectRemoveNulls(obj[key])
		}
	})
}

export const jsonSetupElemenRowId = (obj: any) => {
	if (!obj) {
		return
	}

	if (Array.isArray(obj)) {
		obj.forEach((row) => {
			jsonSetupElemenRowId(row)
		})
	} else if (typeof (obj) === "object") {
		if (!obj._elementRowId) {
			obj._elementRowId = uuidv4();
		}

		const keys = Object.keys(obj)
		keys.forEach((key) => {
			jsonSetupElemenRowId(obj[key])
		})
	}
}


export const randomElementIds = (num: number = 10) => {
	let result = []
	for (let i = 0; i < num; i++) {
		const id = uuidv4().replace("-","") as never
		result.push(id)
	}

	return result;
}


export const clone = (obj) => {
	if (obj === null) {
		return obj
	}
	return JSON.parse(JSON.stringify(obj));
}


export const normalizeString = (arg: string) => {
	return (arg ?? "").trim().toLocaleUpperCase()
}