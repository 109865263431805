import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => {
      //console.log("AxiosResponse<Response<User>>", response)
      return response.data
    })
    .then((response: Response<User>) => {
      //console.log("Response<User>", response)
      return response.data
    })
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getSurveyAnswer = (id: ID): Promise<any> => {
  return axios
    .get(`/api/survey/getSurveyAnswer`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const setSurveyAnswer = (row: any): Promise<any> => {
  return axios
    .post(`/api/survey/setSurveyAnswer`, row)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getCategoryAnswer = (id: ID): Promise<any> => {
  return axios
    .get(`/api/survey/getCategoryAnswer`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const setCategoryAnswer = (row: any): Promise<any> => {
  return axios
    .post(`/api/survey/setCategoryAnswer`, row)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getLookups = (): Promise<any> => {
  return axios
    .get(`/api/survey/getLookups`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getSurveyCategoryInfo = (surveyRowId: ID): Promise<any> => {
  return axios
    .get(`/api/survey/getSurveyCategoryInfo?surveyRowId=${surveyRowId}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const surveyParm = (surveyRowId, categoryRowId, surveyAnswerCategoryRowId) =>
  `surveyRowId=${surveyRowId}&categoryRowId=${categoryRowId}` + (surveyAnswerCategoryRowId ? `&surveyAnswerCategoryRowId=${surveyAnswerCategoryRowId}` : '')

const getSurvey = (surveyRowId, categoryRowId, surveyAnswerCategoryRowId): Promise<any> => {
  return axios
    .get(`/api/survey/getSurvey?${surveyParm(surveyRowId, categoryRowId, surveyAnswerCategoryRowId)}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const updateAnswer = (row: any, surveyRowId, categoryRowId, surveyAnswerCategoryRowId): Promise<any> => {
  return axios
    .post(`/api/survey/updateAnswer?${surveyParm(surveyRowId, categoryRowId, surveyAnswerCategoryRowId)}`, row)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const updateProgramMonth = (surveyRowId, categoryRowId, surveyAnswerCategoryRowId, description): Promise<any> => {
    const row = { description }
    return axios
        .post(`/api/survey/updateProgramMonth?${surveyParm(surveyRowId, categoryRowId, surveyAnswerCategoryRowId)}`, row)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data)
}

const updateStarRating = (star, surveyRowId, categoryRowId, surveyAnswerCategoryRowId): Promise<any> => {
    const row = { star }
    return axios
        .post(`/api/survey/updateStarRating?${surveyParm(surveyRowId, categoryRowId, surveyAnswerCategoryRowId)}`, row)
        .then((response: AxiosResponse<Response<any>>) => response.data)
        .then((response: Response<any>) => response.data)
}


const removeAnswerCategory = (surveyRowId, categoryRowId, surveyAnswerCategoryRowId): Promise<any> => {
  return axios
    .post(`/api/survey/removeAnswerCategory?${surveyParm(surveyRowId, categoryRowId, surveyAnswerCategoryRowId)}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const addAnswerCategory = (surveyRowId, categoryRowId, name1, name2): Promise<any> => {
  const row = { name1, name2 }
  return axios
    .post(`/api/survey/addAnswerCategory?${surveyParm(surveyRowId, categoryRowId, null)}`, row)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const renameAnswerCategory = (surveyRowId, categoryRowId, surveyAnswerCategoryRowId, name2): Promise<any> => {
  const row = { name2 }
  return axios
    .post(`/api/survey/renameAnswerCategory?${surveyParm(surveyRowId, categoryRowId, surveyAnswerCategoryRowId)}`, row)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const getSurveyQuestions = (categoryRowId): Promise<any> => {
  return axios
    .get(`/api/survey/getSurveyQuestions?categoryRowId=${categoryRowId}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const updateSurveyQuestions = (row: any, categoryRowId): Promise<any> => {
  return axios
    .post(`/api/survey/updateSurveyQuestions?categoryRowId=${categoryRowId}`, row)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const addressSuggest = (address: string): Promise<any | undefined> => {
  const parm = { address }
  return axios
    .post(`/api/address/suggest`, parm)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const addressInfo = (address: string): Promise<any | undefined> => {
  const parm = { address }
  return axios
    .post(`/api/address/info`, parm)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

export { getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, getSurveyAnswer, setSurveyAnswer, getCategoryAnswer, setCategoryAnswer, getSurvey, getSurveyQuestions, updateAnswer, updateSurveyQuestions, getLookups, getSurveyCategoryInfo, removeAnswerCategory, addAnswerCategory, renameAnswerCategory, addressSuggest, addressInfo, updateProgramMonth, updateStarRating }
