import Swal from 'sweetalert2'

export const aboutDialog = () => {
    const html =
        "<div style='text-align:justify'>" + "<b>The Mental Health Programs (MHP) Inventory </b>is an online geographic database of mental health supports for public safety personnel across Ontario. The inventory serves as a networking tool, allowing services across public safety sectors in Ontario to identify mental health and wellness programming and resources available to employees at public safety organizations throughout the province based on proximity and availability." + "</div>" +
        "<br/>" +
        "<div style='text-align:justify'>" + "The MHP Inventory, powered by the Geographic Information System (GIS), is now available for access to organizational wellness leads across the Ontario Public Service and Broader Public Service, supporting municipal, First Nations, and provincial public safety organizations." + "</div>" +
        "";
    Swal.fire({
        title: "<b>About</b>",
        html: html,
        //icon: 'success',
        confirmButtonText: "Ok",
        width: '1000px',
        buttonsStyling: false,
        customClass: {
            confirmButton: "btn btn-light-primary"
        }
    })
}

export const contactUsUrl = () => 'mailto:MH.Secretariat@ontario.ca'
    