/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, Link } from 'react-router-dom'
import { KTIcon } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { isUserAdmin, isUserJTSAdmin, isShowOrganizations, resetGlobalQueryRequestSurveysList, CATEGORY_ORGANIZATION_ROWID, getEnvReportsUrl } from '../../../../helpers'

const SidebarMenuAdmin = () => {
	const intl = useIntl()
	const navigate = useNavigate();

	const beforeSurveyList = () => {
		resetGlobalQueryRequestSurveysList()
	}

	return (
		<>
			{isUserAdmin() &&
				<SidebarMenuItem
					to='/users/list'
					icon='profile-circle'
					title='User management'
					fontIcon='bi-app-indicator'
				/>
			}

			{isUserJTSAdmin() &&
				<SidebarMenuItem
					to={`/survey/admin?categoryRowId=${CATEGORY_ORGANIZATION_ROWID}`}
					icon='gear'
					title='Survey Configuration'
					fontIcon='bi-app-indicator'
				/>
			}

			<div className='menu-item'>
				<div className='menu-content pt-8 pb-2'>
					<span className='menu-section text-muted text-uppercase fs-8 ls-1'>Lists</span>
				</div>
			</div>

			{isShowOrganizations() &&
				<SidebarMenuItem
					to='/organizations'
					icon='archive'
					title='Organizations'
					fontIcon='bi-app-indicator'
				/>
			}

			<SidebarMenuItem
				to='/survey/list'
				icon='save-2'
				title='Surveys'
				fontIcon='bi-app-indicator'
				onClick={beforeSurveyList}
			/>

			<SidebarMenuItem
				to='/announcements/list'
				icon='scroll'
				title='Announcement Bulletins'
				fontIcon='bi-app-indicator'
			/>

			{isUserAdmin() &&
				<SidebarMenuItem
					to={getEnvReportsUrl()}
					target='_blank'
					icon='menu'
					title='Reports'
					fontIcon='bi-app-indicator'
				/>

			}

			{isUserJTSAdmin() &&
				<>
					<div className='menu-item'>
						<div className='menu-content pt-8 pb-2'>
							<span className='menu-section text-muted text-uppercase fs-8 ls-1'>Configure Lookups</span>
						</div>
					</div>

					<SidebarMenuItem
						to='/admin/category'
						icon='category'
						title='Categories'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>

					<SidebarMenuItem
						to='/admin/general?reftable=targetAudiences'
						icon='text'
						title='Target Audiences'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>

					<SidebarMenuItem
						to='/admin/general?reftable=staffTypes'
						icon='profile-user'
						title='Staff Types'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>

					<SidebarMenuItem
						to='/admin/general?reftable=deliveryModes'
						icon='rocket'
						title='Delivery Modes'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>

					<SidebarMenuItem
						to='/admin/general?reftable=governanceLevels'
						icon='flag'
						title='Governance Levels'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>

					<SidebarMenuItem
						to='/admin/general?reftable=sectors'
						icon='share'
						title='Sectors'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>

					<SidebarMenuItem
						to='/admin/subsector'
						icon='rescue'
						title='Sub-Sectors'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>

					<SidebarMenuItem
						to='/admin/general?reftable=provinces'
						icon='map'
						title='Provinces'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>

					<SidebarMenuItem
						to='/admin/general?reftable=regions'
						icon='compass'
						title='Regions'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>

					<SidebarMenuItem
						to='/admin/general?reftable=contactTypes'
						icon='technology'
						title='Contact Types'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>



				</>
			}

			{isUserAdmin() &&
				<>
					<div className='menu-item'>
						<div className='menu-content pt-8 pb-2'>
							<span className='menu-section text-muted text-uppercase fs-8 ls-1'>Dashboard</span>
						</div>
					</div>

					<SidebarMenuItem
						to='/admin/externalLink'
						icon='exit-right-corner'
						title='External Links'
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>

					<SidebarMenuItem
						to='/admin/whatsNew'
						icon='abstract-46'
						title="What's New"
						fontIcon='bi-app-indicator'
						hasBullet={false}
					/>



				</>
			}

		</>
	)
}


export { SidebarMenuAdmin }
