import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../_metronic/helpers'
import { UserModel } from './_models'



export const getUserLoginAzureToken = (token: string, graph_token: string, in_azure_login: boolean): Promise<any> => {
	return axios
		.post(`/getUserLoginAzureToken`, { token, graph_token, in_azure_login })
		.then((response) => {
			return response.data
		})
}

export const confirmUserOrganization = (currentUserRowId: string, organizationRowId: string): Promise<any> => {
	return axios
		.post(`/confirmUserOrganization`, { currentUserRowId, organizationRowId })
		.then((response) => {
			return response.data
		})
}
