import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useParams } from 'react-router';
import { SidebarMenuMain } from './SidebarMenuMain'
import { SidebarMenuAdmin } from './SidebarMenuAdmin'
import { SidebarMenuDashboard } from './SidebarMenuDashboard'
import { SidebarMenuCategoriesWrapper } from './SidebarMenuCategoriesWrapper'
import { getSurveyParms } from '../../../../helpers'


const SidebarMenu = () => {
	const location = useLocation();
	const surveyParm = getSurveyParms(useParams());
	//console.log('surveyParm', surveyParm)

	//console.log("useParams()", useParams());
	//console.log("surveyParm2", surveyParm);
	//console.log("location=", location);
	let showMenuCategories = (surveyParm != null);
	let showMenuAdmin = location.pathname.startsWith("/users/list") || location.pathname.startsWith("/survey/admin") || location.pathname.startsWith("/admin");
	let showMenuDashboard = location.pathname.startsWith("/dashboard");
	//console.log("showMenuAdmin", showMenuAdmin)
	//let showMenuCategories = false
	return (
		<div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
			<div
				id='kt_app_sidebar_menu_wrapper'
				className='app-sidebar-wrapper hover-scroll-overlay-y my-5'
				data-kt-scroll='true'
				data-kt-scroll-activate='true'
				data-kt-scroll-height='auto'
				data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
				data-kt-scroll-wrappers='#kt_app_sidebar_menu'
				data-kt-scroll-offset='5px'
				data-kt-scroll-save-state='true'
			>
				<div
					className='menu menu-column menu-rounded menu-sub-indention px-3'
					id='#kt_app_sidebar_menu'
					data-kt-menu='true'
					data-kt-menu-expand='false'
				>

					{
						(
							showMenuDashboard ? <SidebarMenuAdmin /> :
								showMenuAdmin ? <SidebarMenuAdmin /> :
									showMenuCategories ? <SidebarMenuCategoriesWrapper categoryRowId={surveyParm.categoryRowId} surveyRowId={surveyParm.surveyRowId} surveyAnswerCategoryRowId={surveyParm.surveyAnswerCategoryRowId} /> :
										<SidebarMenuAdmin />
						)
					}

					{/* <SidebarMenuMain /> */}
					{/* <SidebarMenuCategories /> */}
				</div>
			</div>
		</div>
	)
}

export { SidebarMenu }
