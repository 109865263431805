import { LogLevel } from "@azure/msal-browser";
import { getEnvData  } from "../../../_metronic/helpers";

export const getMsalConfig = () => {
	const envdata = getEnvData()?.azureAd

	const auth_env = {
		clientId: envdata?.clientId,
		authority: 'https://login.microsoftonline.com/' + envdata?.tenantId,
		redirectUri: envdata?.redirectUri,

		postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
		navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
	}

	const msalConfig = {
		auth: auth_env,
		cache: {
			cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
			storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
		},
		system: {
			loggerOptions: {
				loggerCallback: (level, message, containsPii) => {
					return
					if (containsPii) {
						return;
					}
					switch (level) {
						case LogLevel.Error:
							console.error(message);
							return;
						case LogLevel.Info:
							console.info(message);
							return;
						case LogLevel.Verbose:
							console.debug(message);
							return;
						case LogLevel.Warning:
							console.warn(message);
							return;
						default:
							return;
					}
				},
			},
		},
	};

	console.log('authConfig.msalConfig', msalConfig)

	return msalConfig
}


export const getApiConfig = () => {
	const envdata = getEnvData()?.azureAd

	return {
		resourceUri: envdata?.resourceUri,
		resourceScopes: [envdata?.resourceScope]
	}
}


export const GetLoginRequest = () => {
	return {
		scopes: ["openid", "profile", "offline_access", ...getApiConfig().resourceScopes]
	}
};

export const GetGraphRequest = () => {
	return {
		scopes: ["https://graph.microsoft.com/.default"]
	}
};


export const getSilentRequest = () => {
	return {
		scopes: ["openid", "profile", ...getApiConfig().resourceScopes]
	}
};

export const getTokenRequest = () => {
	return {
		scopes: [...getApiConfig().resourceScopes]
	}
}
