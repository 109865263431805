type GlobalInitialQueryRequestType = {
  surveysList: any
}


export const GlobalInitialQueryRequest: GlobalInitialQueryRequestType = {
  surveysList: null
}

export const updateGlobalQueryRequestSurveysList = (newValue) => {
  newValue = JSON.parse(JSON.stringify(newValue))
  const exists = GlobalInitialQueryRequest.surveysList

  let resetPage = false
  if (exists) {
    if (!(exists.search == newValue.search && exists.sort == newValue.sort && exists.order == newValue.order)) {
      //console.log('resetPage')
      resetPage = true
    }
  }

  if (resetPage) {
    newValue.page = 1
  }
  GlobalInitialQueryRequest.surveysList = newValue
}

export const resetGlobalQueryRequestSurveysList = () => {
  GlobalInitialQueryRequest.surveysList = null
}
