import Moment from 'moment';

export const formatDateTime = (dt: any) => {
	if (dt) {
		Moment.locale('en');
		return Moment(dt).format('D MMM YYYY, h:mm A')
	} else {
		return ''
	}
}

export const formatDate = (dt: any) => {
	if (dt) {
		Moment.locale('en');
		return Moment(dt).format('D MMM YYYY')
	} else {
		return ''
	}
}

export const formatTime = (dt: any) => {
	if (dt) {
		Moment.locale('en');
		return Moment(dt).format('h:mm A')
	} else {
		return ''
	}
}
