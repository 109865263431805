import { useEffect, useState } from 'react'
import clsx from 'clsx';
import { Outlet, useLocation } from 'react-router-dom'
import { HeaderWrapper } from './components/header'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { ScrollTop } from './components/scroll-top'
import { Content } from './components/content'
import { FooterWrapper } from './components/footer'
import { Sidebar } from './components/sidebar'
import {
	DrawerMessenger,
	ActivityDrawer,
	InviteUsers,
	UpgradePlan,
	ThemeModeProvider,
} from '../partials'
import { PageDataProvider } from './core'
import { reInitMenu, getEnvTimeoutSeconds } from '../helpers'
import { ToolbarWrapper } from './components/toolbar'
import { hasSidebar } from './MasterLayoutCustomization'
import { useMsal } from '@azure/msal-react';
import { v4 as uuidv4 } from 'uuid';
import { AuthLastLogin } from '../../app/modules/authentication/components/Auth2'
import { TimeoutClosingWindow } from './components/auth/TimeoutClosingWindow'



const MasterLayout = () => {
	const location = useLocation()

	//const showSidebar = hasSidebar(location)
	const showSidebar = true
	//console.log('showSidebar', showSidebar)


	useEffect(() => {
		reInitMenu()
	}, [location.key])


	//const { instance } = useMsal();
	useEffect(() => {
		console.log("AAAAA 2")

		let seconds = 0
		const max_seconds = getEnvTimeoutSeconds()

		document.addEventListener('keydown', function (event) {
			//console.log('Key pressed is:', event.key);
			seconds = 0
		});
		document.addEventListener('mousedown', function (event) {
			//console.log('mousedown:', event.buttons);
			seconds = 0
		});

		const timeoutId = setInterval(async () => {
			if (seconds >= 0) {
				seconds++
			}
			//console.log('seconds:', seconds);
			//	AuthLastLogin.timeout = "1";
			//	await instance.logoutPopup()
			//console.log('seconds=', seconds);
			if (seconds >= max_seconds && max_seconds > 0) {
				seconds = -1
				console.log("!!!! SHOW !!!!")
				//AuthLastLogin.timeout = "1";

				const handleClose = (arg) => {
					setTimeoutClosingWindowShow(false)
					seconds = 0
					//resolve(arg)
				}

				const showName1 = true
				setTimeoutClosingWindowOptions({ handleClose, showName1, changeStamp: uuidv4(), usedNames: null })
				setTimeoutClosingWindowShow(true)

				//await instance.logoutPopup()
			}

		}, 1000);

		return () => clearTimeout(timeoutId);

		//// Cleanup function to clear the timeout if the component unmounts
		//return () => clearTimeout(timeoutId);

		//const timeoutId = setTimeout(async () => {
		//	//alert('Delayed message after 3 seconds!');
		//	AuthLastLogin.timeout = "1";
		//	await instance.logoutPopup()
		//}, 10000);

		//// Cleanup function to clear the timeout if the component unmounts
		//return () => clearTimeout(timeoutId);
	}, []);


	const [timeoutClosingWindowData1, setTimeoutClosingWindowData1] = useState('')
	const [timeoutClosingWindowData2, setTimeoutClosingWindowData2] = useState('')
	const [timeoutClosingWindowShow, setTimeoutClosingWindowShow] = useState(false)
	const [timeoutClosingWindowOptions, setTimeoutClosingWindowOptions] = useState({ handleClose: (any) => { }, showName1: true, changeStamp: '', usedNames: null })



	return (
		<PageDataProvider>
			<ThemeModeProvider>
				<div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
					<div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
						<HeaderWrapper />
						<div className={clsx('app-wrapper flex-column flex-row-fluid', !showSidebar && 'kt_app_wrapper_without_sidebar')} id='kt_app_wrapper' >
							{showSidebar && <Sidebar />}
							<div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
								<div className='d-flex flex-column flex-column-fluid'>
									<ToolbarWrapper />
									<Content>
										<Outlet />
									</Content>
								</div>
								<FooterWrapper />
							</div>
						</div>
						<TimeoutClosingWindow vname1={timeoutClosingWindowData1} vname2={timeoutClosingWindowData2} show={timeoutClosingWindowShow} options={timeoutClosingWindowOptions} />
					</div>
				</div>

				{/*<ActivityDrawer />*/}
				{/*<DrawerMessenger />*/}

				{/*<InviteUsers />*/}
				{/*<UpgradePlan />*/}
				<ScrollTop />
			</ThemeModeProvider>
		</PageDataProvider>
	)
}

export { MasterLayout }
