import * as Yup from 'yup'
import { RESTRICTION_NON_NEGATIVE, RESTRICTION_POSITIVE } from '.'

export const validateEmail = async (arg: any) => {
	try {
		await Yup.string()
			.email('must be a valid email')
			.validate(arg);
		return true
	} catch (error: any) {
		return false
	}
}

export const validateEmail2 = (arg: any) => {
	if (!arg) return ""

	const regex = /^.+@\w+\.\w+.*$/i;
	if (regex.test(arg)) {
		return ""
	} else {
		return "Invalid Email"
	}
}

export const validatePhone = (arg: any) => {
	if (!arg) return ""

	//console.log("validatePhone", arg)

	const regex1 = /^\d{3}(-)?\d{3}(-)?\d{4}$/i;
	const regex2 = /^\(\d{3}\)\s*\d{3}(-)?\d{4}$/i;

	if (regex1.test(arg)) {
		return ""
	} else if (regex2.test(arg)) {
		return ""
	} else {
		return "Invalid Phone"
	}
}

export const validatePostalCode = (arg: any) => {
	if (!arg) return ""

	const regex = /^[a-z]\d[a-z]\s?(\d[a-z]\d)?$/i;
	if (regex.test(arg)) {
		return ""
	} else {
		return "Invalid Postal Code"
	}
}

export const validate_notnull = (arg: any) => {
	//console.log('validate_notnull', arg)
	if (typeof arg === 'number') return true;
	if (!arg) return false;
	return true;
}



export const validateRestriction = (arg: any, restriction: string) => {
	if (typeof arg === 'number') {
		if (restriction == RESTRICTION_NON_NEGATIVE) {
			if (arg < 0) {
				return "Negative values are not allowed"
			}
		} else if (restriction == RESTRICTION_POSITIVE) {
			if (arg <= 0) {
				return "Positive value required"
			}
		}
	}

	return ""
}
