/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import React from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';


const containerStyle = {
	width: '400px',
	height: '400px'
};

const center = {
	lat: -3.745,
	lng: -38.523
};


const MapPage: FC = () => {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
	})

	const [map, setMap] = React.useState(null)

	const onLoad = React.useCallback(function callback(map) {
		// This is just an example of getting and using the map instance!!! don't just blindly copy!
		const bounds = new window.google.maps.LatLngBounds(center);
		map.fitBounds(bounds);

		setMap(map)
	}, [])

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null)
	}, [])

	return isLoaded ? (
		<div className='row gy-5 g-xl-8'>
			<div className='col-xl-12'>
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={center}
					zoom={10}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					{ /* Child components, such as markers, info windows, etc. */}
					<></>
				</GoogleMap>
			</div>
		</div>
	) : <></>
}


const MapWrapper: FC = () => {
	const intl = useIntl()
	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
			<MapPage />
		</>
	)
}

export default React.memo(MapWrapper)

