/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ILayout, useLayout } from '../../core'
import { isUserAdmin, isUserJTSAdmin, isShowDashboard, CATEGORY_ORGANIZATION_ROWID, getEnvReportsUrl, getEnvGeoSearchUrl } from '../../../helpers'

export const Footer = () => {
	const { config } = useLayout()
	useEffect(() => {
		updateDOM(config)
	}, [config])

	//return (<div>AAAA</div>)

	return (
		<>
			<ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1 flex-wrap justify-content-center'>

				{isShowDashboard() &&
					<li className='menu-item footer-menu-item'>
						<Link to='/dashboard' className='menu-link px-2 py-0'>
							Dashboard
						</Link>
					</li>
				}

				<li className='menu-item footer-menu-item'>
					<a href={getEnvGeoSearchUrl()} className='menu-link px-2 py-0' target='_blank'>
						Geo Search
					</a>
				</li>

				{isUserAdmin() &&
					<li className='menu-item footer-menu-item'>
						<Link to='/users/list' className='menu-link px-2 py-0'>
							User management
						</Link>
					</li>
				}

				{isUserJTSAdmin() &&
					<li className='menu-item footer-menu-item'>
						<Link to={`/survey/admin?categoryRowId=${CATEGORY_ORGANIZATION_ROWID}`} className='menu-link px-2 py-0'>
							Survey Configuration
						</Link>
					</li>
				}

				{true &&
					<li className='menu-item footer-menu-item'>
						<Link to='/organizations' className='menu-link px-2 py-0'>
							Organizations
						</Link>
					</li>
				}

				<li className='menu-item footer-menu-item'>
					<Link to='/survey/list' className='menu-link px-2 py-0'>
						Surveys
					</Link>
				</li>

				<li className='menu-item footer-menu-item'>
					<Link to='/announcements/list' className='menu-link px-2 py-0'>
						Announcement Bulletins
					</Link>
				</li>

				{isUserAdmin() &&
					<li className='menu-item footer-menu-item'>
						<a href={getEnvReportsUrl()} className='menu-link px-2 py-0' target='_blank'>
							Reports
						</a>
					</li>
				}

				{isUserJTSAdmin() &&
					<>
						<li className='menu-item footer-menu-item'>
							<Link to='/admin/category' className='menu-link px-2 py-0'>
								Categories
							</Link>
						</li>

						<li className='menu-item footer-menu-item'>
							<Link to='/admin/general?reftable=targetAudiences' className='menu-link px-2 py-0'>
								Target Audiences
							</Link>
						</li>

						<li className='menu-item footer-menu-item'>
							<Link to='/admin/general?reftable=staffTypes' className='menu-link px-2 py-0'>
								Staff Types
							</Link>
						</li>

						<li className='menu-item footer-menu-item'>
							<Link to='/admin/general?reftable=deliveryModes' className='menu-link px-2 py-0'>
								Delivery Modes
							</Link>
						</li>

						<li className='menu-item footer-menu-item'>
							<Link to='/admin/general?reftable=governanceLevels' className='menu-link px-2 py-0'>
								Governance Levels
							</Link>
						</li>

						<li className='menu-item footer-menu-item'>
							<Link to='/admin/general?reftable=sectors' className='menu-link px-2 py-0'>
								Sectors
							</Link>
						</li>

						<li className='menu-item footer-menu-item'>
							<Link to='/admin/subsector' className='menu-link px-2 py-0'>
								Sub-Sectors
							</Link>
						</li>

						<li className='menu-item footer-menu-item'>
							<Link to='/admin/general?reftable=provinces' className='menu-link px-2 py-0'>
								Provinces
							</Link>
						</li>

						<li className='menu-item footer-menu-item'>
							<Link to='/admin/general?reftable=regions' className='menu-link px-2 py-0'>
								Regions
							</Link>
						</li>

						<li className='menu-item footer-menu-item'>
							<Link to='/admin/general?reftable=contactTypes' className='menu-link px-2 py-0'>
								Contact Types
							</Link>
						</li>

					</>
				}

				{isUserAdmin() &&
					<>
						<li className='menu-item footer-menu-item'>
							<Link to='/admin/externalLink' className='menu-link px-2 py-0'>
								External Links
							</Link>
						</li>

						<li className='menu-item footer-menu-item'>
							<Link to='/admin/whatsNew' className='menu-link px-2 py-0'>
								What's New
							</Link>
						</li>
					</>
				}


			</ul>

			{false &&
				<div className='text-dark order-2 order-md-1'>
					<span className='text-muted fw-semibold me-1'>
						{new Date().getFullYear().toString()}&copy;
					</span>
					<a
						href='https://keenthemes.com/'
						target='_blank'
						className='text-gray-800 text-hover-primary'
					>
						Keenthemes
					</a>
				</div>
			}

			{false &&
				<ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
					<li className='menu-item footer-menu-item'>
						<a href='https://keenthemes.com/' target='_blank' className='menu-link px-2 py-0'>
							About
						</a>
					</li>

					<li className='menu-item footer-menu-item'>
						<a href='https://devs.keenthemes.com/' target='_blank' className='menu-link px-2 py-0'>
							Support
						</a>
					</li>

					<li className='menu-item footer-menu-item'>
						<a
							href='https://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469'
							target='_blank'
							className='menu-link px-2'
						>
							Purchase
						</a>
					</li>
				</ul>
			}
		</>
	)
}

const updateDOM = (config: ILayout) => {
	if (config.app?.footer?.fixed?.desktop) {
		document.body.classList.add('data-kt-app-footer-fixed', 'true')
	}

	if (config.app?.footer?.fixed?.mobile) {
		document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
	}
}


