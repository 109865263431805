import React from 'react'

export const PageCurrentTitle: React.FC<{ title1: string, title2: string, title3: string }> = ({ title1, title2, title3 }) =>
	<div className="app-toolbar py-3 py-lg-6">
		<div className="d-flex flex-stack container-fluid ps-5">

			<div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex flex-wrap me-3 flex-column justify-content-center">
				<h1 className="page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center fw-bolder">{title1}</h1>
				{(title2 || title3) &&
					<ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
						<li className="breadcrumb-item text-muted"><a className="text-muted text-hover-primary" href="#">{title2}</a></li>
						{title3 &&
							<>
								<li className="breadcrumb-item"><span className="bullet bg-gray-400 w-5px h-2px"></span></li>
								<li className="breadcrumb-item text-dark">{title3}</li>
							</>
						}
					</ul>}
			</div>

		</div>
	</div>