import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import MapWrapper from '../pages/map/MapWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
	const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
	const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
	const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
	const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
	const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
	const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
	const SurveyPage = lazy(() => import('../modules/surveys/SurveyPage'))
	const UsersManagerPage = lazy(() => import('../modules/users/UsersPage'))
	const OrganizationsPage = lazy(() => import('../modules/organizations/OrganizationsPage'))
	const AnnouncementsPage = lazy(() => import('../modules/announcements/AnnouncementsPage'))
	const SurveyAdminPage = lazy(() => import('../modules/surveyadmin/SurveyAdmin'))
	const AdminPage = lazy(() => import('../modules/admin/AdminPage'))

	return (
		<Routes>
			<Route element={<MasterLayout />}>
				{/* Redirect to Dashboard after success login/registartion */}
				<Route path='auth/*' element={<Navigate to='/dashboard' />} />
				{/* Pages */}
				<Route path='dashboard' element={<DashboardWrapper />} />
				<Route path='map' element={<MapWrapper />} />
				<Route path='builder' element={<BuilderPageWrapper />} />
				<Route path='menu-test' element={<MenuTestPage />} />
				{/* Lazy Modules */}
				<Route
					path='crafted/pages/profile/*'
					element={
						<SuspensedView>
							<ProfilePage />
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/pages/wizards/*'
					element={
						<SuspensedView>
							<WizardsPage />
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/widgets/*'
					element={
						<SuspensedView>
							<WidgetsPage />
						</SuspensedView>
					}
				/>
				<Route
					path='crafted/account/*'
					element={
						<SuspensedView>
							<AccountPage />
						</SuspensedView>
					}
				/>
				<Route
					path='apps/chat/*'
					element={
						<SuspensedView>
							<ChatPage />
						</SuspensedView>
					}
				/>

				<Route
					path='apps/user-management/*'
					element={
						<SuspensedView>
							<UsersPage />
						</SuspensedView>
					}
				/>

				<Route
					path='survey/*'
					element={
						<SuspensedView>
							<SurveyPage />
						</SuspensedView>
					}
				/>

				<Route
					path='admin/*'
					element={
						<SuspensedView>
							<AdminPage />
						</SuspensedView>
					}
				/>

				<Route
					path='users/*'
					element={
						<SuspensedView>
							<UsersManagerPage />
						</SuspensedView>
					}
				/>

				<Route
					path='organizations/*'
					element={
						<SuspensedView>
							<OrganizationsPage />
						</SuspensedView>
					}
				/>

				<Route
					path='announcements/*'
					element={
						<SuspensedView>
							<AnnouncementsPage />
						</SuspensedView>
					}
				/>

				<Route
					path='surveyadmin/*'
					element={
						<SuspensedView>
							<SurveyAdminPage />
						</SuspensedView>
					}
				/>


				{/* Page Not Found */}
				<Route path='*' element={<Navigate to='/error/404' />} />
			</Route>
		</Routes>
	)
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
	const baseColor = getCSSVariableValue('--bs-primary')
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	})
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
