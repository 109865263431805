/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { useMsal } from '@azure/msal-react';
import { toAbsoluteUrl, setInAzureLogin, aboutDialog, contactUsUrl } from '../../../../_metronic/helpers'
import { AuthLastLogin } from './Auth2'
import { GetLoginRequest } from '../authConfig'
import { browserName, browserVersion } from "react-device-detect"

export const Login = () => {
	useEffect(() => {
		document.body.classList.add('bg-body')
		return () => {
			document.body.classList.remove('bg-body')
		}
	}, [])


	useEffect(() => {
		setInAzureLogin(false)
	}, [])


	const { instance } = useMsal();
	const handleLoginPopup = () => {
		AuthLastLogin.email = "";
		AuthLastLogin.timeout = "";


		/**
		 * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
		 * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
		 * For more information, please follow this link: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
		 */
		instance
			.loginRedirect({
			//.loginPopup({
				...GetLoginRequest(),
				redirectUri: '/redirect',
			})
			.then((arr) => {
				console.log("arr3", arr)
				window.location.reload()
			})
			.catch((error) => console.log(error));
	};

	return (
		<div
			className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
			style={{
				backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
			}}
		>

			{/*<img alt="Logo" src="/media/logos/logo-full.png" className="h-50px ms-5 mt-5" style={{ width: '175px' }} />*/}

			<div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
				<img alt="Logo" src="/media/logos/logo-full.png" className="h-50px mb-10" style={{ width: '175px' }} />

				<h1>
					{/*<img alt="Logo" src="/media/logos/logo.png" className="h-25px w-25px me-5 app-sidebar-logo-default d-none2" />*/}
					MHP Inventory
				</h1>

				{AuthLastLogin.email !== "" && AuthLastLogin.email !== null &&
					<div className="alert alert-danger" role="alert">
						<span>User <b>{AuthLastLogin.email}</b> does not have access to MHP Inventory application. Please contact admin.</span>
					</div>
				}

				{AuthLastLogin.timeout !== "" && AuthLastLogin.timeout !== null &&
					<div className="alert alert-info" role="alert">
						<span>A timeout occurred. Login again.</span>
					</div>
				}

				<button
					type='submit'
					id='kt_sign_in_submit'
					className='btn btn-primary mt-10'
					style={{ minWidth: '180px' }}
					onClick={handleLoginPopup}
				>
					<span className='indicator-label' >Login</span>
				</button>
				<a href='#' className='mb-12 d-none'>
					<img alt='Logo' src={toAbsoluteUrl('/media/logos/default.svg')} className='h-45px' />
				</a>
				<div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto d-none'>
					<button
						type='submit'
						id='kt_sign_in_submit'
						className='btn btn-primary'
					>
						<span className='indicator-label'>Continue</span>
					</button>
				</div>
			</div>
			<div className='d-flex flex-center flex-column-auto p-10'>
				<div className='d-flex align-items-center fw-bold fs-6'>
					{/*<img alt="Logo" src="/media/logos/logo-full.png" className="h-50px me-20 app-sidebar-logo-default" />*/}

					<a href='#' className='text-muted text-hover-primary px-2' onClick={() => aboutDialog()}>
						About
					</a>

					<a href={contactUsUrl()} className='text-muted text-hover-primary px-2'>
						Contact Us
					</a>
				</div>
			</div>
		</div>
	)
}

