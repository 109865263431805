import { useCookies } from 'react-cookie'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import { Modal } from 'react-bootstrap'
import { useMsal } from '@azure/msal-react';
import { normalizeString, KTIcon, getIsAspNetUser, logoutAspNetUser, getEnvTimeoutCountdownSeconds } from '../../../helpers'
import { AuthLastLogin } from '../../../../app/modules/authentication/components/Auth2'
import { removeMsalAuth } from '../../../../app/modules/authentication/components/AuthHelpers'
//	'../../app/modules/authentication/components/Auth2'

type Props = {
	vname1: string
	vname2: string
	show: boolean
	options: { handleClose: (any) => void, showName1: boolean, changeStamp: string, usedNames?: any }
}

export const TimeoutClosingWindow: React.FC<Props> = ({ show, options, vname1, vname2 }) => {
	const countdown_seconds = getEnvTimeoutCountdownSeconds()
	const [removeCookie] = useCookies();
	const [seconds, setSeconds] = useState(countdown_seconds)
	const { instance } = useMsal()
	const navigate = useNavigate()


	//console.log("seconds222", seconds)
	if (show && seconds <= 0) {
		console.log("!!!! TIMEOUT !!!!")
		AuthLastLogin.timeout = "1";

		if (getIsAspNetUser()) {
			logoutAspNetUser(removeCookie, navigate)

		} else {
			removeMsalAuth()
			instance
				.logout({
					onRedirectNavigate: (url) => {
						window.location.reload();
						return false
					}
				})
				.catch((error) => {
					console.log("logout.error", error)
					navigate('/')
					document.location.reload()
				})
		}
	}

	useEffect(() => {
		setName1(vname1)
		setName2(vname2)
		setSeconds(countdown_seconds)
		//console.log("useEffect", seconds)

		let timeoutId = null
		if (show) {
			timeoutId = setInterval(async () => {
				setSeconds((prevSeconds) => prevSeconds - 1)
				console.log("countdown.seconds", seconds)
			}, 1000);
		}

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId)
			};
		}
	}, [vname1, vname2, options.changeStamp])

	useEffect(() => {


	}, [])


	const [name1, setName1] = useState(vname1)
	const [name2, setName2] = useState(vname2)

	const dissmissLocation = () => {
		setSeconds(() => countdown_seconds)
		options.handleClose(null)
	}
	const applyLocation = () => {
		let errors = []

		if (options.showName1 && !name1) {
			errors.push('"Existing Program Name" is required')
		}
		if (!name2) {
			errors.push('"New Program Name" is required')
		}
		if (options.showName1 && name1 && name2 && normalizeString(name1) == normalizeString(name2)) {
			errors.push('"Existing Program Name" and "New Program Name" should not matched')
		}
		if (options.usedNames) {
			const used = options.usedNames.find(x => (normalizeString(x) == normalizeString(name1) || normalizeString(x) == normalizeString(name2)))
			console.log('used', used)
			if (used) {
				errors.push(`Name "${used}" already used`)
			}

		}

		if (errors.length > 0) {
			Swal.fire({
				html: errors.join("<br/>"),
				icon: 'error',
				confirmButtonText: "Ok",
				buttonsStyling: false,
				customClass: {
					confirmButton: "btn btn-danger"
				}
			})
		}
		else {
			options.handleClose({ name1, name2 })
		}
	}


	return (
		<Modal
			className='modal fade'
			id='kt_modal_select_location'
			data-backdrop='static'
			tabIndex={-1}
			role='dialog'
			show={show}
			aria-hidden='true'
			onHide={dissmissLocation}
		>
			<div className='modal-content'>
				<div className='modal-header'>
					<h5 className='modal-title'>Timeout</h5>

					{false &&
						<div
							className='btn btn-icon btn-sm btn-active-light-primary ms-2'
							onClick={dissmissLocation}
						>
							<KTIcon iconName='cross' className='fs-2x' />
						</div>
					}
				</div>

				<div className='modal-body'>
					<div className='fv-row mb-10'>
						<label className='fs-6 mb-2 ms-2'>
							<span>The timeout will occur after </span>
							<span><b>{seconds}</b></span>
							<span> seconds</span>
						</label>
					</div>
				</div>

				<div className='modal-footer'>
					<button type='button' className='btn btn-primary' onClick={dissmissLocation}>
						Continue Work
					</button>
					{false &&
						<button id='submit' type='button' className='btn btn-primary' onClick={applyLocation}>
							Apply
						</button>
					}
				</div>
			</div>
		</Modal>
	)
}


