/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from 'react'
import { Fragment } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type Props = {
    info: any,
}

export const MemberLocations: FC<Props> = ({ info }) => {
    const membersLocations = info.membersLocations
    console.log('membersLocations', membersLocations)

    return (
        <div className={`card card-flush mb-5 mb-xl-10`}>
            <div className='card-header pt-0'>
                <div className='card-title d-flex flex-column m-0'>
                    <div className='d-flex align-items-center d-none'>
                        <span className='fs-4 fw-semibold text-gray-400 me-1 align-self-start'>$</span>

                        <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>69,700</span>

                        <span className='badge badge-light-success fs-base'>
                            <KTIcon iconName='arrow-up' className='fs-5 text-success ms-n1' /> 2.2%
                        </span>
                    </div>
                    <div className='card-title text-gray-800 fw-bold dashboard-h3'>
                        Members Location
                    </div>
                </div>
            </div>

            <div className='card-body pt-1 pb-1 d-flex flex-wrap align-items-center'>
                <div className='table-responsive w-100 mt-2' >
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' >
                        <thead>
                            <tr className='fw-bold text-muted'>
                                <th className='min2-w-150px pt-1'>Region</th>
                                <th className='min-w-50px pt-1 text-end' style={{ width: '120px' }}># Organizations</th>
                                <th className='min-w-50px pt-1 text-end' style={{ width: '90px' }}># Programs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {membersLocations.map((row, index) => (
                                <Fragment key={`dashboard-member-location-rows-${index}`}>
                                    <tr>
                                        <td className='pt-1'>
                                            <span className='fw-bolder text-gray-700 text-muted fw-semibold text-muted d-block fs-7'>
                                                {row.region}
                                            </span>
                                        </td>
                                        <td className='pt-1 text-end'>
                                            <span className='fw-bolder text-gray-700 text-muted fs-7 fw-semibold'>
                                                {row.numOrganizations ? row.numOrganizations : '-'}
                                            </span>
                                        </td>
                                        <td className='pt-1 text-end'>
                                            <span className='fw-bolder text-gray-700 text-muted fs-7 fw-semibold'>
                                                {row.numPrograms ? row.numPrograms : '-'}
                                            </span>
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}


                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

const initChart = function (
    chartSize: number = 70,
    chartLine: number = 11,
    chartRotate: number = 145
) {
    const el = document.getElementById('kt_card_widget_17_chart')
    if (!el) {
        return
    }
    el.innerHTML = ''

    var options = {
        size: chartSize,
        lineWidth: chartLine,
        rotate: chartRotate,
        //percent:  el.getAttribute('data-kt-percent') ,
    }

    const canvas = document.createElement('canvas')
    const span = document.createElement('span')

    // @ts-ignore
    if (typeof G_vmlCanvasManager !== 'undefined') {
        // @ts-ignore
        G_vmlCanvasManager.initElement(canvas)
    }

    const ctx = canvas.getContext('2d')
    canvas.width = canvas.height = options.size

    el.appendChild(span)
    el.appendChild(canvas)

    // @ts-ignore
    ctx.translate(options.size / 2, options.size / 2) // change center
    // @ts-ignore
    ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

    //imd = ctx.getImageData(0, 0, 240, 240);
    const radius = (options.size - options.lineWidth) / 2

    const drawCircle = function (color: string, lineWidth: number, percent: number) {
        percent = Math.min(Math.max(0, percent || 1), 1)
        if (!ctx) {
            return
        }

        ctx.beginPath()
        ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
        ctx.strokeStyle = color
        ctx.lineCap = 'round' // butt, round or square
        ctx.lineWidth = lineWidth
        ctx.stroke()
    }

    // Init 2
    drawCircle('#E4E6EF', options.lineWidth, 100 / 100)
    drawCircle(getCSSVariableValue('--bs-primary'), options.lineWidth, 100 / 150)
    drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth, 100 / 250)
}


