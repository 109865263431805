/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'

type Props = {
	info: any,
}


//const rows: Array<{ title: string, text: string }> = [
//    { title: 'News 1', text: 'Very important news 1' },
//    { title: 'News 2', text: 'Not very important news 2' },
//    { title: 'Loooong News', text: 'Very long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long long news. A lot of text. End' },
//]

export const WhatsNew = ({ info }: Props) => {
	const rows = info.whatsNews

	return (
		<div className={`card card-flush mb-5 mb-xl-10`}>
			<div className='card-header pt-5'>
				<div className='card-title text-gray-800 fw-bold dashboard-h3'>What's New</div>
				<div className='card-toolbar'></div>
			</div>
			<div className='card-body pt-5'>
				{rows.map((row, index) => (
					<Fragment key={`lw26-rows-${index}`}>
						<div className='d-flex flex-stack'>

							<div className='symbol symbol-45px me-5 d-none'>
								<img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='' />
							</div>
							<div className='d-flex justify-content-start flex-column'>
								<a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
									{row.header}
								</a>
								<span className='text-muted fw-semibold text-muted d-block fs-7'>
									{row.text}
								</span>
							</div>
						</div>
						{rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
					</Fragment>
				))}
			</div>
		</div>
	)
}