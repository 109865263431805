/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import clsx from 'clsx'
import { useNavigate, Link } from "react-router-dom";
import linq from 'linq'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { ID, getCategoryRowId, getCategoryOrganization, getCategoryProvider, getParentCategoryRowId, surveyCategoryUrl, navigateTo, getServiceProviderProgramsRowId } from '../../../../helpers'


type Props = {
	lookups: any,
	categoryRowId: ID,
	surveyRowId: ID,
	surveyAnswerCategoryRowId: ID,
	categoryInfo: Array<ID>
	isPrimaryServiceProvider: boolean
}


export const SidebarMenuCategories: React.FC<Props> = ({ lookups, categoryRowId, surveyAnswerCategoryRowId, surveyRowId, categoryInfo, isPrimaryServiceProvider }) => {
	//console.log('isPrimaryServiceProvider', isPrimaryServiceProvider)
	//console.log('categoryInfo', categoryInfo)
	//console.log('surveyAnswerCategoryRowId', surveyAnswerCategoryRowId)
	const navigate = useNavigate();
	const parentCategoryRowId = getParentCategoryRowId(lookups, categoryRowId)


	const categoryOrganizationRowId = getCategoryOrganization(lookups)
	const categoryProviderRowId = getCategoryProvider(lookups)
	const categoryCategoryRowId = getCategoryRowId(lookups)
	const categoryProgramsRowId = getServiceProviderProgramsRowId(lookups)
	const rootCategoryRowId = (isPrimaryServiceProvider ? categoryProgramsRowId : categoryCategoryRowId)
	const rootCategoryText = (isPrimaryServiceProvider ? 'Service Provider Programs' : 'Categories')

	const categories = linq.from(lookups.categories)
		.where((val: any) => val.parentCategoryRowId == null && val.surveyTypeRowId == rootCategoryRowId)
		.orderBy((val: any) => val.name)
		.toArray();

	categories.forEach((category: any) => {
		const subCategories = linq.from(lookups.categories)
			.where((val: any) => val.parentCategoryRowId == category.rowId)
			.orderBy((val: any) => val.name)
			.toArray();
		category.subCategories = subCategories;
	});

	const onLinkClick = (e: any, url: any) => {
		e.preventDefault()
		navigateTo(navigate, url)
	};


	const getAnswerCategoryRows = (categoryRowId) => {
		return (categoryInfo.find((x: any) => x.categoryRowId == categoryRowId) as any)?.surveyAnswerCategoryRowIds
	}


	const organizationUrl = `survey/general/${surveyRowId}/${categoryOrganizationRowId}`
	const providerUrl = `survey/general/${surveyRowId}/${categoryProviderRowId}`
	const categoryUrl = (catRowId, surveyAnswerCategoryRowId) => surveyCategoryUrl(surveyRowId, catRowId, surveyAnswerCategoryRowId)

	return (
		<>
			{!isPrimaryServiceProvider && <>
				<div className="menu-item ">
					<Link data-main-content className={"menu-link" + (categoryRowId == categoryOrganizationRowId ? " active" : "")} to={organizationUrl} onClick={(e) => onLinkClick(e, organizationUrl)}>
						{countElement(categoryOrganizationRowId, categoryInfo)}

						<span className="menu-icon">
							<i className="ki-duotone ki-bank fs-2">
								<span className="path1"></span>
								<span className="path2"></span>
							</i>
						</span>

						<span className="menu-title">Organization</span>
					</Link>
				</div>
			</>}

			{isPrimaryServiceProvider && <>
				<div className="menu-item">
					<Link data-main-content className={"menu-link" + (categoryRowId == categoryProviderRowId ? " active" : "")} to={providerUrl} onClick={(e) => onLinkClick(e, providerUrl)}>
						{countElement(categoryProviderRowId, categoryInfo)}

						<span className="menu-icon">
							<i className="bi bi-layer-forward fs-2">
								<span className="path1"></span>
								<span className="path2"></span>
							</i>
						</span>

						<span className="menu-title">Primary Service Provider Report</span>
					</Link>
				</div>
			</>}


			{true && <>
				<div className="menu-item pt-5">
					<div className="menu-content">
						<span className="menu-heading fw-bold text-uppercase fs-7">{rootCategoryText}</span>
					</div>
				</div>

				{categories.map((category: any) => {
					const isActive = category.rowId == parentCategoryRowId
					return (
						<div key={category.rowId} data-kt-menu-trigger="click" className={"menu-item menu-accordion" + (isActive ? " here show" : "")}>
							<a href="#" className="menu-link">
									{countElementCategory(category, categoryInfo, lookups)}
									<span className="menu-title">{category.name}</span>
									<span className="menu-arrow"></span>
							</a>

							<div className={clsx("menu-sub menu-sub-accordion", { 'menu-active-bg': isActive })}>
								{category.subCategories.map((subCategory: any) => (
									<div key={subCategory.rowId} className="menu-item">
										{!getAnswerCategoryRows(subCategory.rowId) &&
											<Link className={"menu-link" + (subCategory.rowId == categoryRowId ? " active" : "")}
												to={categoryUrl(subCategory.rowId, null)}
												onClick={(e) => onLinkClick(e, categoryUrl(subCategory.rowId, null))}
											>
												{countElement(subCategory.rowId, categoryInfo)}
												<span className="menu-title">{subCategory.name}</span>
											</Link>
										}

										{getAnswerCategoryRows(subCategory.rowId) &&
											<>
												<Link className={"menu-link" + (subCategory.rowId == categoryRowId ? "" : "")} to='#'>
													{countElementSubCategory(subCategory, categoryInfo, lookups)}
													<span className="menu-title">{subCategory.name}</span>
												</Link>
												{getAnswerCategoryRows(subCategory.rowId).map((surveyAnswerCategoryRow: any) => (
													<Link className={"ms-5 menu-link" + (surveyAnswerCategoryRow.rowId == surveyAnswerCategoryRowId ? " active" : "")}
														key={surveyAnswerCategoryRow.rowId}
														to={categoryUrl(subCategory.rowId, surveyAnswerCategoryRow.rowId)}
														onClick={(e) => onLinkClick(e, categoryUrl(subCategory.rowId, surveyAnswerCategoryRow.rowId))}
													>
														<span className='menu-bullet'>
															<span className={'bullet bullet-dot ' + (surveyAnswerCategoryRow.isExists ? 'bg-success' : 'bg-warning')}></span>
														</span>

														{/*{countElement(subCategory.rowId, categoryInfo)}*/}
														<span className="menu-title">{surveyAnswerCategoryRow.description}</span>
													</Link>
												))}
											</>
										}
									</div>
								))}
							</div>
						</div>
					)
				})}
			</>}
		</>
	)
}

const countElement = (categoryRowId, categoryInfo) => {
	//const count = 0
	//console.log('categoryInfo', categoryInfo)
	const exists = categoryInfo.map(x => x.categoryRowId).includes(categoryRowId)
	const count = exists ? 1 : 0

	let backgroundcolor, color, colorclass
	if (count > 0) {
		colorclass = 'badge-light-success'
		color = '#000000'
		backgroundcolor = '#A3CCB5'
	} else {
		colorclass = 'badge-light-warning'
		color = '#000000'
		backgroundcolor = 'rgb(255, 199, 0)'
	}

	return (
		<div className='d-flex align-items-center'>
			<div className='d-flex flex-column'>
				<span className={`me-2 ${colorclass}`} style={{ backgroundColor: backgroundcolor, color: color, width: '1.6rem', height: '1.6rem', fontSize: '0.8rem', borderRadius: '50%', padding: '0', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
					{/*{`${count}`}*/}
				</span>
			</div>
		</div>
	)

	return (
		<div className='d-flex align-items-center'>
			<div className='d-flex flex-column'>
				<span className={'bullet bullet-dot me-2 h-10px w-10px ' + (exists ? 'bg-success' : 'bg-warning')}></span>
			</div>
		</div>
	)
}

const countElementCategory = (category, categoryInfo, lookups) => {
	const subCategories = category.subCategories.map(x => x.rowId)
	const allcount = subCategories.length

	//console.log('subCategories', subCategories)

	let count = 0
	subCategories.forEach((categoryRowId) => {
		const item = categoryInfo.find(x => x.categoryRowId == categoryRowId)
		if (item) {
			count += item.surveyAnswerCategoryRowIds ? item.surveyAnswerCategoryRowIds.length : 1
		}
	})

	let backgroundcolor, color, colorclass
	if (count > 0) {
		colorclass = 'badge-light-success'
		color = '#000000'
		backgroundcolor = '#A3CCB5'
	} else {
		colorclass = 'badge-light-warning'
		color = '#000000'
		backgroundcolor = 'rgb(255, 199, 0)'
	}

	return (
		<div className='d-flex align-items-center'>
			<div className='d-flex flex-column'>
				<span className={`me-2 ${colorclass}`} style={{ backgroundColor: backgroundcolor, fontWeight: 'bold', color: color, width: '1.8rem', height: '1.8rem', fontSize: '0.9rem', borderRadius: '50%', padding: '0', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
					{/*{`${count}`}*/}
				</span>
			</div>
		</div>
	)
}

const countElementSubCategory = (subCategory, categoryInfo, lookups) => {
	//console.log('subCategory', subCategory)
	const item = categoryInfo.find(x => x.categoryRowId == subCategory.rowId)
	//console.log('item', item)

	let count = 0
	let countall = 0
	item.surveyAnswerCategoryRowIds.forEach((answerCategoryItem) => {
		countall++
		if (answerCategoryItem.isExists) {
			count++
		}
	})

	let backgroundcolor, color, colorclass
	if (count > 0) {
		colorclass = 'badge-light-success'
		color = '#000000'
		backgroundcolor = '#A3CCB5'
	} else {
		colorclass = 'badge-light-warning'
		color = '#000000'
		backgroundcolor = 'rgb(255, 199, 0)'
	}

	return (
		<div className='d-flex align-items-center'>
			<div className='d-flex flex-column'>
				<span className={`me-2 ${colorclass}`} style={{ backgroundColor: backgroundcolor, color: color, width: '1.6rem', height: '1.6rem', fontSize: '0.8rem', borderRadius: '50%', padding: '0', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
					{/*{`${count}`}*/}
				</span>
			</div>
		</div>
	)
}




