import { NavigateFunction } from "react-router-dom";
import { ID, CATEGORY_ORGANIZATION_ROWID, CATEGORY_PRIMARYSERVICEPROVIDER_ROWID, isUserProvider } from ".";


export async function navigateTo(navigate: NavigateFunction, url: string) {
    //console.log("currentNavigationCallback", currentNavigationCallback)
    if (currentNavigationCallback) {
        const result = await currentNavigationCallback()
        if (!result) {
            return false
        }
    }
    currentNavigationCallback = null
    navigate(url)
    return true
}

export function setNavigationCallback(navigationCallback: any) {
    currentNavigationCallback = navigationCallback
    //console.log("setNavigationCallback", navigationCallback)
}

const emptyNavigationCallback = (): Promise<boolean> => {
    return Promise.resolve(true)
}

let currentNavigationCallback: any = null

export function surveyUrl(surveyRowId: ID) {
    return surveyCategoryUrl(surveyRowId, isUserProvider() ? CATEGORY_PRIMARYSERVICEPROVIDER_ROWID : CATEGORY_ORGANIZATION_ROWID)
}

export function surveyCategoryUrl(surveyRowId: ID, catRowId: ID, surveyAnswerCategoryRowId: ID = null) {
    let url = `/survey/general/${surveyRowId}/${catRowId}`
    if (surveyAnswerCategoryRowId) {
        url += `/${surveyAnswerCategoryRowId}`
    }
    return url
}

export async function navigateToSurvey(navigate: NavigateFunction, surveyRowId: ID) {
    const url = surveyUrl(surveyRowId)
    return await navigateTo(navigate, url)
}

export function surveyListUrl() {
    const url = `/survey/list`
    return url
}


export async function navigateSurveyList(navigate: NavigateFunction) {
    const url = surveyListUrl()
    return await navigateTo(navigate, url)
}


export interface ISurveyParm {
    surveyRowId: string
    categoryRowId: string
    surveyAnswerCategoryRowId?: string
}

export const getSurveyParms = (pm): ISurveyParm => {
    const str = pm['*']
    if (str) {
        const match1 = str.match(/^general\/(.{36})\/(.{36})$/);
        if (match1 && match1[1] && match1[2]) {
            const result = {
                surveyRowId: match1[1],
                categoryRowId: match1[2],
            } as ISurveyParm
            return result
        }

        const match2 = str.match(/^general\/(.{36})\/(.{36})\/(.{36})$/);
        if (match2 && match2[1] && match2[2] && match2[3]) {
            const result = {
                surveyRowId: match2[1],
                categoryRowId: match2[2],
                surveyAnswerCategoryRowId: match2[3],
            } as ISurveyParm
            return result
        }
    }

    if (pm.surveyRowId && pm.categoryRowId) {
        const result = {
            surveyRowId: pm.surveyRowId,
            categoryRowId: pm.categoryRowId,
            surveyAnswerCategoryRowId: pm.surveyAnswerCategoryRowId,
        } as ISurveyParm
        return result
    }

    return null
}

export const textSurveyParm = (surveyRowId: ID, categoryRowId: ID, surveyAnswerCategoryRowId: ID) => {
    return "surveyRowId=" + surveyRowId + " && " + "categoryRowId=" + categoryRowId + " && " + "surveyAnswerCategoryRowId=" + surveyAnswerCategoryRowId;
}
