import { useQuery } from 'react-query'
import { useParams } from 'react-router';
import { ConfirmAccount } from './ConfirmAccount'
import { isNotEmpty, QUERIES } from '../../../../_metronic/helpers'
import { getUserLoginToken } from '../core/_requests'

const ConfirmAccountWrapper = () => {
	var tokenRowId = useParams().tokenRowId
	//console.log("tokenRowId", tokenRowId)
	const enabledQuery: boolean = true

	const {
		isLoading,
		data,
		error,
	} = useQuery(
		`${QUERIES.GET_CONFIRM_ACCOUNT}-${tokenRowId}`,
		() => {
			return getUserLoginToken(tokenRowId)
		},
		{
			cacheTime: 0,
			enabled: enabledQuery,
			onError: (err) => {
				//setItemIdForUpdate(undefined)
				//console.error(err)
			},
		}
	)



	if (!isLoading && !error && data) {
		return <ConfirmAccount rowdata={data} />
	}

	return null
}

export { ConfirmAccountWrapper }
