import React, { useRef, createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState, } from 'react'
import { useNavigate } from 'react-router-dom'
import { toAbsoluteUrl, getLookupData } from '../../../../_metronic/helpers'
import { confirmUserOrganization, getUserLoginAzureToken } from '../core/_requests'
import { AuthLastLogin } from './Auth2'
import Select2 from '../../../../_metronic/assets/js/select2'
import linq from 'linq'
import Swal from 'sweetalert2'

export const ChooseOrganization: FC<{ pdata: any }> = ({ pdata }) => {

	const [enableSubmit, setEnableSubmit] = useState(true)
	const navigate = useNavigate();

	useEffect(() => {
		console.log("UUUUUUUUUU4", inputReference.current)
		inputReference.current.el.focus();
	}, [])

	const handleChooseOrganization = async () => {

		const organizationRowId = inputReference.current.el.val()

		if (!organizationRowId) {
			Swal.fire({
				text: "You must select your organization",
				icon: 'error',
				confirmButtonText: "Ok",
				buttonsStyling: false,
				customClass: {
					confirmButton: "btn btn-light-primary"
				}
			})

		} else {
			const organizationRow = linq.from(AuthLastLogin.listChooseOrganizations).where((x: any) => x.rowId == organizationRowId).firstOrDefault() as any
			const organizationName = organizationRow.OrganizationName

			const result = await Swal.fire({
				title: 'Please confirm',
				html: `Selected Organization:<br/><b>${organizationName}</b>`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes'
			})
			if (result.isConfirmed === true) {
				const rdata = await confirmUserOrganization(AuthLastLogin.currentUserRowId, organizationRowId)
				setEnableSubmit(false)
				if (rdata === true) {
					await getUserLoginAzureToken(AuthLastLogin.api_token, AuthLastLogin.graph_token, false)
					pdata.setShowChooseOrganizationScreen(false)
					navigate('/')
					document.location.reload()
				}
			}
		}
	};

	const data = linq.from(AuthLastLogin.listChooseOrganizations).select((val: any) => ({ id: val.rowId, text: val.OrganizationName })).toArray()
	const inputReference = useRef(null);

	return (
		<div
			className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
			style={{
				backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
			}}
		>
			<div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
				<h1>MHP Inventory</h1>

				{/*{AuthLastLogin.email !== "" && AuthLastLogin.email !== null &&*/}
				{/*    <div className="alert alert-danger" role="alert">*/}
				{/*        <span>User <b>{AuthLastLogin.email}</b> does not have access to MHP Inventory application. Please contact admin.</span>*/}
				{/*    </div>*/}
				{/*}*/}

				{/*{AuthLastLogin.timeout !== "" && AuthLastLogin.timeout !== null &&*/}
				{/*    <div className="alert alert-info" role="alert">*/}
				{/*        <span>A timeout occurred. Login again.</span>*/}
				{/*    </div>*/}
				{/*}*/}


				<div className='col-lg-12 fv-row mt-10' style={{ maxWidth: '1000px' }}>
					<label className='col-lg-12 col-form-label required fw-bold fs-6' htmlFor='organization'>Select Your Organization</label>
					<Select2
						ref={inputReference}
						className={'form-select mb-2 form-control form-control-lg form-control-solid'}
						data={data}
						name='organization'
						options={
							{
								placeholder: '',
								minimumResultsForSearch: 1,
								multiple: false,
							}
						}
					/>
				</div>

				<button
					type='submit'
					id='kt_sign_in_submit'
					className='btn btn-primary mt-10'
					style={{ minWidth: '180px' }}
					disabled={!enableSubmit}
					onClick={handleChooseOrganization}
				>
					<span className='indicator-label' >Submit</span>
				</button>
				<a href='#' className='mb-12 d-none'>
					<img alt='Logo' src={toAbsoluteUrl('/media/logos/default.svg')} className='h-45px' />
				</a>
				<div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto d-none'>
					<button
						type='submit'
						id='kt_sign_in_submit'
						className='btn btn-primary'
					>
						<span className='indicator-label'>Continue</span>
					</button>
				</div>
			</div>
			<div className='d-flex flex-center flex-column-auto p-10'>
				{false &&
					<div className='d-flex align-items-center fw-bold fs-6'>
						<a href='#' className='text-muted text-hover-primary px-2'>
							About
						</a>

						<a href='#' className='text-muted text-hover-primary px-2'>
							Contact
						</a>

						<a href='#' className='text-muted text-hover-primary px-2'>
							Contact Us
						</a>
					</div>
				}
			</div>
		</div >
	)


}


