import { AuthModel } from '../core/_models'
import { getIsAspNetUser, userEmail } from '../../../../_metronic/helpers'

const AUTH_MSAL_LOCAL_STORAGE_KEY = 'kt-auth-msal-react-v'

const getMsalAuth = (): AuthModel | undefined => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = localStorage.getItem(AUTH_MSAL_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return
    }

    try {
        const auth: AuthModel = JSON.parse(lsValue) as AuthModel
        if (auth) {
            // You can easily check auth_token expiration also
            return auth
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}

const setMsalAuth = (auth: AuthModel) => {
    if (!localStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(auth)
        localStorage.setItem(AUTH_MSAL_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
}

const removeMsalAuth = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(AUTH_MSAL_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
    }
}

export function getAuthorizationHeader() {
    if (getIsAspNetUser()) {
        return `Email ${userEmail()}`
    } else {
        const auth = getMsalAuth()
        if (auth && auth.api_token) {
            return `Bearer ${auth.api_token}`
        }
    }
    return ''
}

export function setupMsalAxios(axios: any) {
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
        (config: { headers: { Authorization: string } }) => {
            config.headers.Authorization = getAuthorizationHeader()
            return config
        },
        (err: any) => Promise.reject(err)
    )
}

export { getMsalAuth, setMsalAuth, removeMsalAuth, AUTH_MSAL_LOCAL_STORAGE_KEY }
