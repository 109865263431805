import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export function maskPhone() {
    const mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    return mask
}

export function maskPostCode() {
    const mask = [/[A-Z]/i, /[0-9]/, /[A-Z]/i, " ", /[0-9]/, /[A-Z]/i, /[0-9]/]
    return mask
}

export function maskMoney() {
    const numberMask = createNumberMask({
        decimalLimit: 2, 
        allowDecimal: true,
        prefix: '$ ',
        //suffix: ' $' // This will put the dollar sign at the end, with a space.
    })
    return numberMask
}

export function maskPercent() {
    const numberMask = createNumberMask({
        //decimalLimit: 2,
        allowDecimal: true,
        prefix: '',
        suffix: ' %' // This will put the dollar sign at the end, with a space.
    })
    return numberMask
}

export function maskNumber() {
  const numberMask = createNumberMask({
    //decimalLimit: 2,
    allowDecimal: true,
    prefix: '',
    suffix: '',
  })
  return numberMask
}

export function maskLanLot() {
    const numberMask = createNumberMask({
        decimalLimit: 5,
        allowDecimal: true,
        prefix: '',
        suffix: '',
    })
    return numberMask
}