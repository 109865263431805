import {
	FC,
	useState,
	useEffect,
	useRef,
} from 'react'
import { useMsal } from '@azure/msal-react';
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { GetLoginRequest, GetGraphRequest } from '../authConfig'
import { getUserLoginAzureToken } from '../core/_requests'
import { WithChildren, getInAzureLogin, setInAzureLogin } from '../../../../_metronic/helpers'
import { getMsalAuth, setMsalAuth } from '../../authentication/components/AuthHelpers';
import { ChooseOrganization } from './ChooseOrganization';



export const AuthLastLogin = { email: "", timeout: "", listChooseOrganizations: null, currentUserRowId: "", api_token: "", graph_token: "" }

export const AuthInit2: FC<WithChildren> = ({ children }) => {
	const didRequest = useRef(false)
	const [showSplashScreen, setShowSplashScreen] = useState(true)
	const [showChooseOrganizationScreen, setShowChooseOrganizationScreen] = useState(false)

	const { instance } = useMsal();

	const activeAccount = instance.getActiveAccount();

	//// We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
	useEffect(() => {
		const requestUser = async () => {
			try {
				if (!didRequest.current) {

					const request = {
						...GetLoginRequest(),
						account: activeAccount
					};
					const result = await instance.acquireTokenSilent(request)
					const api_token = result.accessToken

					const request2 = {
						...GetGraphRequest(),
						account: activeAccount
					};
					const result2 = await instance.acquireTokenSilent(request2)
					const graph_token = result2.accessToken


					//console.log("accessToken", result)
					setMsalAuth({ api_token })

					const data = await getUserLoginAzureToken(api_token, graph_token, getInAzureLogin())
					//console.log("data123", data)
					if (!data.isLogin) {
						if (data.isChooseOrganization) {
							AuthLastLogin.listChooseOrganizations = data.listChooseOrganizations
							AuthLastLogin.currentUserRowId = data.userRowId
							AuthLastLogin.api_token = api_token
							AuthLastLogin.graph_token = graph_token
							setShowChooseOrganizationScreen(true)
						} else {
							AuthLastLogin.email = data.email;
							await instance.logoutPopup()
						}
					} else {
						setInAzureLogin(true)
					}

					setShowSplashScreen(false)
					//console.log("data", data)
					//if (data) {
					//setCurrentUser(data)
					//}

					//const result2 = await instance.acquireTokenSilent(request)
					//console.log("result2", result2)
					//console.log("result2==", (result2.accessToken === result.accessToken))
				}
			} catch (error) {
				console.error(error)
				if (!didRequest.current) {
					//logout()
				}
			} finally {
				//setShowSplashScreen(false)
			}

			return () => (didRequest.current = true)
		}

		//console.log("requestUser --- getMsalAuth()", getMsalAuth())
		requestUser()
	}, [])

	const pdata = { setShowChooseOrganizationScreen }

	return showChooseOrganizationScreen ? <ChooseOrganization pdata={pdata} /> : showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}


