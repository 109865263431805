import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
//import './_metronic/assets/sass/core/vendors/plugins/_select2.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { getMsalConfig } from './app/modules/authentication/authConfig';
import { setupMsalAxios } from './app/modules/authentication/components/AuthHelpers';
import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { LoadingIndicator } from './_metronic/helpers/components/LoadingIndicator'
import { QUERIES, getEnvData, setEnvData } from './_metronic/helpers'



/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

//setupAxios(axios)
setupMsalAxios(axios)

Chart.register(...registerables)


const getMsalInstance = () => {
	/**
	 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
	 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
	 */
	const msalInstance = new PublicClientApplication(getMsalConfig());

	// Default to using the first account if no account is active on page load
	if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
		// Account selection logic is app dependent. Adjust as needed for different use cases.
		msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
	}

	// Listen for sign-in event and set active account
	msalInstance.addEventCallback((event) => {
		//console.log("event 1", event)
		if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as any).account) {
			const account = (event.payload as any).account;
			console.log("account 1", account)
			msalInstance.setActiveAccount(account);
		}
	});

	return msalInstance
}

const getEnvInfo = (): Promise<any> => {
	return axios.post<any>("/getEnvInfo", {})
}

/*
export const AppRoutesWrapper: FC = () => {
	const [envInfo, setEnvInfo] = useState(null)

	const {
		isFetching,
		refetch,
		data,
	} = useQuery(
		`${QUERIES.GET_ENV_INFO}`,
		() => {
			return getEnvInfo()
		},
		{ cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
	)


	if (data && !envInfo) {
		setEnvInfo(data)
		envData.data = data.data
	}


	if (isFetching) {
		return LoadingIndicator()
	} else {
		console.log("envData.data", envData.data)
		return (
			<>
				<AppRoutes msalInstance={getMsalInstance()} />
			</>)
	}
}
*/


const renderMain = (container) => {
	createRoot(container).render(
		<QueryClientProvider client={queryClient}>
			<MetronicI18nProvider>
				<AuthProvider>
					<AppRoutes msalInstance={getMsalInstance()} />
					{/*<AppRoutesWrapper />*/}
				</AuthProvider>
			</MetronicI18nProvider>
			{/* <ReactQueryDevtools initialIsOpen={false} /> */}
		</QueryClientProvider>
	)	
}

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
	//console.log("AAAAAAAAA", getEnvData())

	if (getEnvData()) {
		renderMain(container)
	} else {
		getEnvInfo().then((result) => {
			setEnvData(result.data)
			renderMain(container)
		})
	}
}
