import React, { useState, useEffect, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { isUserAdmin, isShowDashboard, CATEGORY_ORGANIZATION_ROWID, DEFAULT_SURVEY_ROWID, getEnvGeoSearchUrl } from '../../../../helpers'
import { NavigationBar } from '../../../../../app/modules/authentication/components/NavigationBar';




export function MenuInner() {



    const intl = useIntl()
    return (
        <>
            {isShowDashboard() ?
                <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
                :
                <MenuItem title='Surveys' to='/survey/list' />
            }

            {false &&
                <MenuItem title='Survey' to={`/survey/general/${DEFAULT_SURVEY_ROWID}/${CATEGORY_ORGANIZATION_ROWID}`} />
            }

            <div className='menu-item me-lg-1'>
                <a className='menu-link py-3' href={getEnvGeoSearchUrl()} target="_blank">Geo Search</a>
            </div>

            {false &&
                <MenuItem title='Geo Search' to={getEnvGeoSearchUrl()} />
            }

            {isUserAdmin() && false &&
                <MenuItem title='Admin' to='/users/list' />
            }

            {false &&
                <NavigationBar />
            }


        </>
    )
}
