import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { getIsAspNetUser, getEnvIsAllowAspnetUser } from '../_metronic/helpers'
import { AuthInit } from './modules/auth'
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from '../app/modules/authentication/components/NavigationBar';
import { Login } from '../app/modules/authentication/components/Login';
import { Login2 } from '../app/modules/auth/components/Login2'
import { AuthLayout } from '../app/modules/auth/AuthPage'
import { AuthInit2 } from '../app/modules/authentication/components/Auth2';

export const App = ({ msalInstance }) => {
	console.log("App.tsx -- init", getIsAspNetUser())

	if (getIsAspNetUser()) {
		return (
			<Suspense fallback={<LayoutSplashScreen />}>
				<I18nProvider>
					<LayoutProvider>
						<Outlet />
						<MasterInit />
					</LayoutProvider>
				</I18nProvider>
			</Suspense>
		)

	} else {
		return (
			<MsalProvider instance={msalInstance}>
				<AuthenticatedTemplate>
					<Suspense fallback={<LayoutSplashScreen />}>
						<I18nProvider>
							<LayoutProvider>
								<AuthInit2>
									<Outlet />
									<MasterInit />
								</AuthInit2>
							</LayoutProvider>
						</I18nProvider>
					</Suspense>
				</AuthenticatedTemplate>
				<UnauthenticatedTemplate>
					<>
						<Routes>
							<Route element={<AuthLayout />}>
								{getEnvIsAllowAspnetUser() &&
									<Route path='/auth2/login2' element={<Login2 />} />
								}
							</Route>
							<Route path='*' element={<Login />} />
						</Routes>
					</>
				</UnauthenticatedTemplate>
			</MsalProvider>
		)
	}

	//< Suspense fallback = {< LayoutSplashScreen />}>
	//    <I18nProvider>
	//        <LayoutProvider>
	//            <AuthInit>
	//                <Outlet />
	//                <MasterInit />
	//            </AuthInit>
	//        </LayoutProvider>
	//    </I18nProvider>
	//</Suspense >

	//return (
	//    <MsalProvider instance={msalInstance}>
	//        <>
	//            <h1>AAAA2</h1>
	//            <NavigationBar />
	//        </>
	//    </MsalProvider>
	//)
}


