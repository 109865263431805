/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'

type Props = {
    info: any,
}

//const rows: Array<{ description: string }> = [
//    { description: 'Avg. Client Rating' },
//    { description: 'Instagram Followers' },
//    { description: 'Google Ads CPC' },
//]

export const ExternalLinks = ({ info }: Props) => {
    const rows = info.externalLinks
    //console.log("info", info)
    //console.log("rows", rows)
    //return null

    return (
        <div className={`card card-flush`}>
            <div className='card-header pt-5'>
                <div className='card-title text-gray-800 fw-bold dashboard-h3'>
                    External Links
                </div>
                <div className='card-toolbar'></div>
            </div>
            <div className='card-body pt-5'>
                {rows.map((row, index) => (
                    <Fragment key={`lw26-rows-${index}`}>
                        <div className='d-flex flex-stack'>
                            <a href={row.linkUrl} target='_blank' className='text-primary fw-semibold fs-6 me-2'>
                                {row.linkText}
                            </a>
                            {true &&
                                <a href={row.linkUrl} target='_blank' title='Open Link'>
                                    <button
                                        type='button'
                                        title='Open Link'
                                        className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                                    >
                                        <KTIcon iconName='exit-right-corner' className='fs-2' />
                                    </button>
                                </a>
                            }
                        </div>
                        {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
                    </Fragment>
                ))}
            </div>
        </div>
    )
}
