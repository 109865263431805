import linq from 'linq'
import { SUBSECTOR_NA, PRIMARY_CONTACT } from './Constants'

export const getLookupData = (rows: any) => linq.from(rows).select((val: any) => ({ id: val.rowId, text: val.name })).toArray();
export const lookupIdByName = (rows: any, value) => (linq.from(rows).singleOrDefault((val: any) => val.name === value) as any)?.rowId;

export const getLookupSubSectors = (lookups: any, sectorRowId: any, governanceLevelRowId: any) => {
	let data = linq.from(lookups.subSectors).where((val: any) => val.sectorRowId === sectorRowId && val.governanceLevelRowId === governanceLevelRowId).toArray()
	if (data.length == 0) {
		data = linq.from(lookups.subSectors).where((val: any) => val.rowId === SUBSECTOR_NA).toArray()
	}
	return getLookupData(data)
}

export const getPrimaryContactRowId = (lookups: any) => {
	let data = (linq.from(lookups.contactTypes).where((val: any) => val.name === PRIMARY_CONTACT).singleOrDefault() as any)?.rowId
	return data
}