const QUERIES = {
	USERS_LIST: 'USERS_LIST',
	ORGANIZATIONS_LIST: 'ORGANIZATIONS_LIST',
	ANNOUNCEMENTS_LIST: 'ANNOUNCEMENTS_LIST',
	SURVEYS_LIST: 'SURVEYS_LIST',
	GET_SURVEY_ORGANIZATION_ANSWER: 'GET_SURVEY_ORGANIZATION_ANSWER',
	GET_SURVEY_CATEGORY_ANSWER: 'GET_SURVEY_CATEGORY_ANSWER',
	GET_SURVEY_PROVIDER_ANSWER: 'GET_SURVEY_PROVIDER_ANSWER',
	GET_SURVEY_FOR_ADMIN: 'GET_SURVEY_FOR_ADMIN',
	GET_SURVEY: 'GET_SURVEY',
	GET_LOOKUPS: 'GET_LOOKUPS',
	GET_ADMIN_LOOKUP: 'GET_ADMIN_LOOKUP',
	SURVEY_USERS_LIST: 'SURVEY_USERS_LIST',
	GET_SURVEY_CATEGORY_INFO: 'GET_SURVEY_CATEGORY_INFO',
	GET_CONFIRM_ACCOUNT: 'GET_CONFIRM_ACCOUNT',
	GET_DASHBOARD_INFO: 'GET_DASHBOARD_INFO',
	GET_ENV_INFO: 'GET_DASHBOARD_INFO',
}

export { QUERIES }
