export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  //console.log("pathname", pathname)
  //console.log("url", url)
  
  //const current = getCurrentUrl(pathname)
  //url = getCurrentUrl(url)
  const current = pathname
  //console.log("current", current)

  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  
  return false
}
