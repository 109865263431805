export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname
export const searchParm = (location: any, parmname: string) => new URLSearchParams(location.search).get(parmname)

export const getFileDownloadName = (response: any) => {
	let result = response.headers.get('content-disposition').split('filename=')[1].split(';')[0]
	//console.log("result", result)
	if (result.startsWith('"') && result.endsWith('"')) {
		result = result.substr(1, result.length - 2)
	}
	//console.log("result", result)
	return result
}

export const getFileUploadName = (filename: string) => {
	let result = filename.trim().replace(new RegExp("[^a-z0-9_\\-\\.]", "gi"), '_')

	if (!result.toLocaleLowerCase().endsWith(".pdf")) {
		result = result + ".pdf"
	}
	return result
}
