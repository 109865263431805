/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ConfirmAccountWrapper } from './components/ConfirmAccountWrapper'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { Login2 } from './components/Login2'
import { toAbsoluteUrl, aboutDialog, contactUsUrl } from '../../../_metronic/helpers'

export const AuthLayout = () => {
    useEffect(() => {
        document.body.classList.add('bg-body')
        return () => {
            document.body.classList.remove('bg-body')
        }
    }, [])

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
            }}
        >
            {/*<img alt="Logo" src="/media/logos/logo-full.png" className="h-50px ms-5 mt-5" style={{ width: '175px' }} />   */}

            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                <img alt="Logo" src="/media/logos/logo-full.png" className="h-50px mb-10" style={{ width: '175px' }} />

                <h1>
                    {/*<img alt="Logo" src="/media/logos/logo.png" className="h-20px w-20px me-5 app-sidebar-logo-default" />*/}
                    MHP Inventory
                </h1>
                <a href='#' className='mb-12 d-none'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/default.svg')} className='h-45px' />
                </a>
                <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <Outlet />
                </div>
            </div>
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fw-bold fs-6'>
                    <a href='#' className='text-muted text-hover-primary px-2' onClick={() => aboutDialog()}>
                        About
                    </a>

                    <a href={contactUsUrl()} className='text-muted text-hover-primary px-2'>
                        Contact Us
                    </a>
                </div>
            </div>
        </div>
    )
}

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path='login' element={<Login />} />
            <Route path='login2' element={<Login2 />} />
            <Route path='registration' element={<Registration />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='confirm-account/:tokenRowId' element={<ConfirmAccountWrapper />} />
            <Route index element={<Login />} />
        </Route>
    </Routes>
)

export { AuthPage }
