/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'

type Props = {
	info: any,
}


export const AnnouncementInfo = ({ info }: Props) => {
	const data = info.announcementInfo
	//console.log("data", data)

	return (
		<div className={`card card-flush`}>
			<div className='card-header pt-5'>
				<div className='card-title text-gray-800 fw-bold dashboard-h3'>
					Announcement
				</div>
				<div className='card-toolbar'></div>
			</div>

			<div className='card-body pt-5'>
				<>
					<div className='d-flex flex-stack'>
						<div className='symbol symbol-45px me-5 d-none'>
							<img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='' />
						</div>
						<div className='d-flex justify-content-start flex-column'>
							<span className='text-muted fw-semibold text-muted d-block fs-7 white-space-pre-wrap' >
									{data?.announcementText}
							</span>
						</div>
					</div>
					{/*<div className='separator separator-dashed my-3' />*/}
				</>

			</div>
		</div>
	)
}