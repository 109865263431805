export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000'

export const CATEGORY = 'Category'
export const SERVICE_PROVIDER_PROGRAMS = 'Service Provider Programs'
export const PRIMARY_SERVICE_PROVIDER = 'Primary Service Provider'
export const ORGANIZATION = 'Organization'
export const ORGANIZATIONAL_STAFF = 'Organizational Staff'
export const THIRD_PARTY_VENDOR = 'Third Party Vendor'
export const BOTH = 'Both'

export const SECTION_ORGANIZATION = 'Organization'
export const SECTION_ORGANIZATION_CONTACT = 'Organization Contact Information'

export const ROLE_ORGANIZATIONAL = 'Organizational Lead'
export const ROLE_PROVIDER = 'Primary Service Provider'
export const ROLE_ADMIN = 'System Admin'
export const ROLE_JTS_ADMIN = 'JTS System Admin'

export const DATETYPE_NUMERIC = 'numeric'
export const DATETYPE_EMAIL = 'email'
export const DATETYPE_PHONE = 'phone'
export const DATETYPE_POSTALCODE = 'postalCode'
export const DATETYPE_LATITUDE = 'latitude'
export const DATETYPE_LONGITUDE = 'longitude'
export const DATETYPE_MONEY = 'money'
export const DATETYPE_PERCENT = 'percent'

export const RESTRICTION_NON_NEGATIVE = 'non-negative'
export const RESTRICTION_POSITIVE = 'positive'
export const RESTRICTIONS_ALL = [ RESTRICTION_NON_NEGATIVE, RESTRICTION_POSITIVE ]

export const STAGE_POSTED = 'Posted'
export const STAGE_SUBMITTED = 'Submitted'
export const STAGE_DECLINED = 'Declined'
export const STAGE_EXPIRED = 'Expired'
export const STAGE_ARCHIVED = 'Archived'
export const STAGES_ALL = [ STAGE_POSTED, STAGE_SUBMITTED, STAGE_DECLINED, STAGE_EXPIRED, STAGE_ARCHIVED ]

export const PROGRAM_DESCRIPTION_FIELD = "__PROGRAM_DESCRIPTION__"
export const STAR_RATING_FIELD = "__STAR_RATING__"

export const MULTI_SELECT2_STR = "__MULTI_SELECT2__"

export const PRIMARY_CONTACT = "Primary Contact"

export const CATEGORY_ORGANIZATION_ROWID = '2610e037-ad50-4f58-a491-171f85768946'
export const CATEGORY_PRIMARYSERVICEPROVIDER_ROWID = '27364359-ccc4-473f-ae5f-accd4388798e'


export const DEFAULT_SURVEY_ROWID = '159716b2-dd88-4699-be3d-c6a245b8bc58'

export const PROVINCE_ONTARIO = 'c4380a01-9217-44c6-b700-8cf7b509b4fb'

export const SUBSECTOR_NA = '302e10e0-baf8-41df-a81c-20c8171840f7'

export const CANCEL_TAG = "__CANCEL_TAG__"
